module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Melissa","short_name":"Melissa","description":"Erleben Sie authentische griechische Küche im Melissa Bar & Restaurant in Nürnberg. Genießen Sie mediterrane Spezialitäten wie Gyros, Souvlaki, Fisch- und Grillgerichte sowie köstliche Desserts. Jetzt entdecken!","start_url":"/","icon":"src/images/icon.png","background_color":"#18191a","theme_color":"#18191a","display":"fullscreen","theme_color_in_head":false,"crossOrigin":"use-credentials","orientation":"portrait","lang":"de","categories":["food"],"dir":"ltr","legacy":true,"cache_busting_mode":"query","include_favicon":true,"cacheDigest":"40138676c3fd164fe6cd4f9df8590928"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://www.melissa-nbg.de"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["dsad"],"pluginConfig":{"head":true,"respectDNT":false,"exclude":[],"origin":"https://www.googletagmanager.com","delayOnRouteUpdate":0},"gtagConfig":{}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
